import { useEffect, useRef } from 'react'
import { useRouter } from 'next/router'
import { useTranslation } from 'next-i18next'

import useCurrentSession from '@/hooks/use-current-session'
import { show as showLoginModal } from '@/features/Auth/login-modal'

const useShowSignup = () => {
  const router = useRouter()
  const { t } = useTranslation()
  const signupQuery = typeof router?.query?.signup === 'string'
  const shownRef = useRef(false)
  const session = useCurrentSession()
  const isLoggedIn = !!session.user_type
  const hasSession = session.loaded && isLoggedIn

  useEffect(() => {
    if (!router.isReady || hasSession || !signupQuery || shownRef.current)
      return
    shownRef.current = true
    showLoginModal({
      mode: 'register',
      eventId: 'web-signup-submit-from-dashboard',
      t
    })
  }, [router, hasSession, signupQuery, t])

  return null
}

export default useShowSignup
