import { useRef } from 'react'
import dynamic from 'next/dynamic'
import Link from 'next/link'
import { useTranslation } from 'next-i18next'
import { serverSideTranslations } from 'next-i18next/serverSideTranslations'
import styled from 'styled-components'

import ENV_VARS from '@/config'
import { breakpoints, colors } from '@/styles'
import useShowSignup from '@/hooks/use-show-signup'
import { makeMainLayout } from '@/layouts/main'
import { HeaderTheme } from '@/features/Header'
import { Headers } from '@/features/Home/elements'
import guidesMobile from '@/features/Home/RentReport/images/guides-mobile.webp'
import guidesWide from '@/features/Home/RentReport/images/guides-wide.webp'
import guides from '@/features/Home/RentReport/images/guides.webp'
import tenancyMobile from '@/features/Home/RentReport/images/tenancy-mobile.webp'
import tenancyWide from '@/features/Home/RentReport/images/tenancy-wide.webp'
import tenancy from '@/features/Home/RentReport/images/tenancy.webp'
import { Banner } from '@/components/banner'
import { Button } from '@/components/button'
import Head from '@/components/head'

const HeroSection = dynamic(() => import('@/features/Home/hero-section'))
const MainWrapper = dynamic(() => import('@/features/Structure/main-wrapper'))
const ForLandlords = dynamic(() => import('@/features/Home/ForLandlords'))
const ForRenters = dynamic(() => import('@/features/Home/ForRenters'))
const Landlords = dynamic(() => import('@/features/Home/Landlords'))
const RentReport = dynamic(() => import('@/features/Home/RentReport'))
const ApplicationDownload = dynamic(
  () => import('@/components/application-download')
)

const { BASE_URL } = ENV_VARS

const Wrapper = styled(MainWrapper)`
  display: flex;
  flex-direction: column;
  overflow: hidden;
`

const MainContentContainer = styled.section``

const RentReportWrapper = styled.div`
  background-color: ${colors.white};
  padding-bottom: 170px;
  min-height: 2300px;
  @media (max-width: ${breakpoints.card}) {
    min-height: 3200px;
  }
  @media (max-width: ${breakpoints.tabletLandscape}) {
    min-height: 2850px;
  }
  @media screen and (max-width: ${breakpoints.phoneMax}) {
    padding-bottom: 100px;
    min-height: 2400px;
  }
`

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 80px;
  @media (max-width: ${breakpoints.phoneMax}) {
    margin-top: 50px;
  }

  a {
    text-decoration: none;
  }
`

const ForLandlordsContainer = styled.div`
  min-height: 2300px;
  @media (max-width: ${breakpoints.card}) {
    min-height: 3100px;
  }
  @media (max-width: ${breakpoints.phoneMax}) {
    min-height: 2900px;
  }
`
const ForRentersContainer = styled.div`
  background-color: ${colors.background};
  min-height: 2000px;
  @media (max-width: ${breakpoints.phoneMax}) {
    min-height: 1900px;
  }
`
const LandlordsContainer = styled.div`
  background-color: ${colors.background};
  min-height: 1000px;
  @media (max-width: ${breakpoints.phoneMax}) {
    min-height: 800px;
  }
`

const ButtonText = styled.span`
  text-align: center;
  min-width: 160px;
`

function Home() {
  const { t } = useTranslation('home')
  useShowSignup()
  const heroSectionRef = useRef()

  const metaTitle = t(
    'home:Apartments, Condos and Houses for Rent in Canada | liv.rent'
  )

  const metaDescription = t(
    `home:Search verified houses and apartments for rent in Canada, apply for listings, sign leases, and pay for rent - all in one place.`
  )

  const metaImageUrl = `${BASE_URL}/images/apartments-for-rent-toronto-vancouver-canada.jpg`

  const reportTexts = {
    topTitle: t('home:Rent Reports'),
    topDesc: t(
      'home:The real cost of rent in Vancouver, Toronto, Alberta, and Montreal so you can stay on top of the latest market trends.'
    ),
    topLink: 'https://liv.rent/blog/rent-reports/',
    topId: 'home-rent-reports-click',
    botLeftTitle: t('home:Neighbourhood guides'),
    botLeftDesc: t(
      "home:Love where you rent with compelling and comprehensive breakdowns of Canada's neighbourhoods."
    ),
    botLeftLink: 'https://liv.rent/blog/neighbourhoods/',
    botLeftId: 'home-neighbourhood-guides-click',
    botRightTitle: t('home:Residential tenancy'),
    botRightDesc: t(
      'home:Your questions about renting, answered. Learn more about navigating the rental landscape.'
    ),
    botRightLink: 'https://liv.rent/blog/landlords/',
    botRightId: 'home-residential-tenancy-click'
  }

  const reportTitle = t('home:Resources')
  const reportSubTitle = t('home:Everything you need to know about renting.')

  const guidesImages = [guides, guidesWide, guidesMobile]
  const tenancyImages = [tenancy, tenancyWide, tenancyMobile]

  return (
    <>
      <Head
        title={metaTitle}
        description={metaDescription}
        meta={{
          image: metaImageUrl,
          imageAlt: t('home:apartments for rent toronto vancouver canada')
        }}
        path="/"
      />
      <Wrapper>
        <HeroSection heroSectionRef={heroSectionRef} />
        <MainContentContainer>
          <ForLandlordsContainer>
            <ForLandlords />
          </ForLandlordsContainer>
          <ForRentersContainer>
            <ForRenters />
          </ForRentersContainer>
          <RentReportWrapper>
            <Headers
              title={reportTitle}
              subTitle={reportSubTitle}
              maxWidth={850}
            />
            <RentReport
              reportTexts={reportTexts}
              botLeftImages={guidesImages}
              botRightImages={tenancyImages}
            />
            <ButtonWrapper>
              <Link href="https://liv.rent/blog/" passHref>
                <Button
                  id="home-viewall-click"
                  theme="secondary"
                  radius={50}
                  shadow
                >
                  <ButtonText>{t('home:View all')}</ButtonText>
                </Button>
              </Link>
            </ButtonWrapper>
          </RentReportWrapper>
          <LandlordsContainer>
            <Landlords />
          </LandlordsContainer>
        </MainContentContainer>
        <ApplicationDownload />
      </Wrapper>
    </>
  )
}

export const getStaticProps = async ({ locale }: { locale: string }) => {
  const translations = await serverSideTranslations(locale, [
    'translation',
    'home'
  ])

  return {
    props: {
      ...translations
    }
  }
}

const headerConfig = {
  theme: HeaderTheme.TRANSPARENT,
  showNavItems: true,
  showSearch: false,
  banner: <Banner />
}

Home.Layout = makeMainLayout({ headerConfig })

export default Home
