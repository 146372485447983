import styled from 'styled-components'

import { breakpoints, colors } from '@/styles'

export const SubHeader = styled.h2`
  margin: 0;
  padding: ${props => props?.padding?.[0] || '160px 0 25px'};
  max-width: ${props => (props.maxWidth ? props.maxWidth : 600)}px;
  text-align: ${props => props?.textAlign?.[0] || 'center'};
  color: ${colors.regular};
  font-family: futura-pt-bold, Futura, sans-serif;
  font-size: 57px;
  font-weight: 700;
  letter-spacing: -0.1px;
  line-height: 54px;
  @media (max-width: ${breakpoints.phoneMax}) {
    text-align: ${props => props?.textAlign?.[1] || 'left'};
    padding: ${props => props?.padding?.[1] || '80px 0 15px'};
    font-size: 34px;
    letter-spacing: -0.09px;
    line-height: 34px;
    max-width: 300px;
  }
`

export const SubHeaderTitle = styled.h1`
  margin: 0;
  padding: ${props => props?.padding?.[0] || '25px 0 70px'};
  color: ${colors.regular};
  font-size: ${props => props?.fontSize?.[0] || '18px'};
  line-height: 24px;
  font-weight: bold;
  letter-spacing: -0.5px;
  text-align: ${props => props?.textAlign?.[0] || 'center'};
  @media (max-width: ${breakpoints.phoneMax}) {
    text-align: ${props => props?.textAlign?.[1] || 'left'};
    padding: ${props => props?.padding?.[1] || '10px 0 40px'};
    font-size: ${props => props?.fontSize?.[1] || '16px'};
    line-height: 24px;
    max-width: 300px;
  }
`

export const TextWrapper = styled.div`
  padding: 0 30px;
  margin: 0 auto;
  max-width: 1210px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: start;
  @media (max-width: ${breakpoints.phoneMax}) {
    align-items: start;
    padding: 0 25px;
    ${SubHeader} {
      max-width: 395px;
    }
  }
`

export const Headers = ({ title, subTitle, maxWidth }) => {
  return (
    <TextWrapper>
      <SubHeaderTitle
        textAlign={['left']}
        padding={['170px 0 15px', '100px 0 15px']}
        fontSize={['24px', '18px']}
      >
        {title}
      </SubHeaderTitle>
      <SubHeader
        textAlign={['left']}
        padding={['0 0 85px', '0 0 50px']}
        maxWidth={maxWidth}
      >
        {subTitle}
      </SubHeader>
    </TextWrapper>
  )
}
