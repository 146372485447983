import { useState } from 'react'
import { useTranslation } from 'next-i18next'
import styled from 'styled-components'
import { isEmpty } from 'ramda'
import {
  Box,
  ButtonIcon,
  IconClose,
  Text,
  TextLink
} from '@machobear/design-system'
import { vars } from '@machobear/design-system/css'

import structure from '@/structure'
import { breakpoints } from '@/styles'
import useCurrentSession from '@/hooks/use-current-session'
import useLocalStorage from '@/hooks/use-localstorage'

const Wrapper = styled.div`
  position: fixed;
  top: ${structure.header.height}px;
  left: 0;
  right: 0;
  width: 100%;
  padding: 17px 60px;
  background: #0a1b46;

  @media (max-width: ${breakpoints.ipadMiniMax}) {
    top: ${structure.header.heightMobile}px;
    padding: 10px 60px;
  }
`

const CloseButton = styled.div`
  background: none;
  border: none;
  flex-shrink: 0;
  margin: 0;
  padding: 0;
  position: fixed;
  right: 24px;

  svg {
    color: ${vars.backgroundColor.neutralLight};
  }
`

export const Banner = () => {
  const [visible, setVisible] = useState(true)
  const [displayedBefore, setDisplayedBefore] = useLocalStorage(
    'giveaway-banner-dismissed',
    false
  )

  const { t } = useTranslation()
  const session = useCurrentSession()

  const closeMessage = () => {
    setVisible(false)
    setDisplayedBefore(true)
  }

  if (
    displayedBefore === undefined ||
    displayedBefore ||
    !visible ||
    isEmpty(session)
  )
    return null

  return (
    <Wrapper>
      <Box
        width="full"
        display="flex"
        alignItems="center"
        justifyContent="center"
        position="relative"
        flexWrap="wrap"
      >
        <Text tone="neutralInverted" align="center" size="large">
          &#127881; {t('GIVEAWAY')} &#127881;{' '}
          {t("We're giving $5,000 away in rent!")}{' '}
          <TextLink
            href="https://renters.liv.rent/free-rent-giveaway/"
            target="_blank"
            weight="weak"
          >
            {t('Enter Giveaway')}
          </TextLink>
        </Text>
        <CloseButton aria-label="close giveaway banner" onClick={closeMessage}>
          <ButtonIcon icon={<IconClose />} variant="transparent" />
        </CloseButton>
      </Box>
    </Wrapper>
  )
}
