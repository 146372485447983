import { useRouter } from 'next/router'

import ENV_VARS from '@/config'

const { BASE_URL } = ENV_VARS

const getCanonicalUrl = (path, locale) => {
  const canonicalPath = path === '/' ? '' : path
  if (locale === 'en') return `${BASE_URL}${canonicalPath}`
  return `${BASE_URL}/${locale}${canonicalPath}`
}

const CanonicalUrl = path => {
  const router = useRouter()
  return getCanonicalUrl(path, router.locale)
}

export { getCanonicalUrl, CanonicalUrl }
