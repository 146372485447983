import NextHead from 'next/head'
import { useRouter } from 'next/router'
import { useTranslation } from 'next-i18next'

import ENV_VARS from '@/config'
import { isEmptyOrNull } from '@/utils'
import { CanonicalUrl } from '@/utils/seo'

const { BASE_URL } = ENV_VARS

interface Meta {
  image?: string
  type?: string
  imageAlt?: string
  price?: string
  keywords?: string
  schema?: any
}

interface PageData {
  page: number
  page_size: number
  total_count: number
}

interface Props {
  title?: string
  description?: string
  meta?: Meta
  path: string
  noRobots?: boolean
  hideCustomerSupport?: boolean
  pageData?: PageData
  children?: React.ReactNode
}

const Head: React.FC<Props> = ({
  title,
  description,
  meta = {},
  path,
  noRobots,
  hideCustomerSupport,
  pageData,
  children
}) => {
  const { t } = useTranslation()
  const router = useRouter()

  const language = router.locale || ''
  const ogLocales: Record<string, string> = {
    en: 'en_US',
    fr: 'fr_CA',
    zh: 'zh_CN'
  }

  const metaTitle =
    title || t('Apartments, Condos and Houses for Rent in Canada | liv.rent')
  const metaDescription =
    description ||
    t(
      'Rent your perfect home with liv.rent. Search across Canada. Houses & apartments for rent in Toronto, Vancouver, Burnaby, Surrey, Coquitlam, Richmond, and more.'
    )

  const metaUrl = CanonicalUrl(path)

  const defaultImage = `${BASE_URL}/images/apartments-for-rent-toronto-vancouver-canada.jpg`
  const metaImage = meta.image || defaultImage

  const metaType = meta.type || 'website'

  const imageAlt = meta.imageAlt || 'apartments for rent canada'

  const organizationLogo = `${BASE_URL}/images/icons/icon-256x256.png`
  const organizationSchema = JSON.stringify({
    '@context': 'http://schema.org',
    '@type': 'Organization',
    name: 'liv.rent',
    url: 'https://liv.rent/',
    logo: organizationLogo,
    image: defaultImage,
    description:
      "Search houses and apartments for rent in Vancouver, Toronto and across Canada. Rely on liv.rent's verified listings and filters to find your next rental home.",
    sameAs: [
      'https://www.facebook.com/liv.rent/',
      'https://www.instagram.com/liv.rent/',
      'https://twitter.com/liv_rent',
      'https://www.linkedin.com/company/livrent/',
      'https://www.youtube.com/channel/UCyx0INp1qqQ2Xhw-rMiqQiA',
      'https://www.pinterest.ca/livrent/'
    ],
    contactPoint: [
      {
        '@type': 'ContactPoint',
        telephone: '+1 604 593 3020',
        contactType: 'customer service',
        email: 'info@liv.rent',
        contactOption: 'TollFree',
        areaServed: 'CA',
        availableLanguage: ['en', 'zh-Hant', 'zh-Hans', 'zh-CN']
      }
    ]
  })

  const paginated = !isEmptyOrNull(pageData)
  const currentPage = pageData?.page || 1
  const showCanonical = !paginated
  const showHrefLang = !paginated || (paginated && currentPage === 1)

  const getPaginationTags = () => {
    const totalRecords = pageData?.total_count || 0
    const pageSize = pageData?.page_size || 12
    const totalPages = Math.ceil(totalRecords / pageSize)
    const isFirstPage = currentPage === 1
    const isSecondPage = currentPage === 2
    const isLastPage = currentPage === totalPages

    const Next = (
      <link key="next" rel="next" href={`${metaUrl}?page=${currentPage + 1}`} />
    )
    const Prev = (
      <link
        key="prev"
        rel="prev"
        href={isSecondPage ? metaUrl : `${metaUrl}?page=${currentPage - 1}`}
      />
    )
    const Canonical = (
      <link
        key="canonical"
        rel="canonical"
        href={isFirstPage ? metaUrl : `${metaUrl}?page=${currentPage}`}
      />
    )

    if (isFirstPage && isLastPage) {
      return [Canonical]
    }
    if (isFirstPage) {
      return [Next, Canonical]
    }
    if (isLastPage) {
      return [Prev, Canonical]
    }
    return [Prev, Next, Canonical]
  }

  return (
    <>
      <NextHead>
        <title>{metaTitle}</title>
        <meta name="description" content={metaDescription} />

        {noRobots && <meta name="robots" content="noindex, nofollow" />}

        <meta property="og:site_name" content="liv.rent" />
        <meta property="og:url" content={metaUrl} />
        <meta property="og:title" content={metaTitle} />
        <meta property="og:description" content={metaDescription} />
        <meta property="og:type" content={metaType} />
        <meta property="og:image" content={metaImage} />
        <meta property="og:image:width" content="1200" />
        <meta property="og:image:height" content="675" />
        <meta property="og:image:alt" content={imageAlt} />
        <meta property="og:locale" content={ogLocales[language]} />
        {meta.price && <meta property="og:price:amount" content={meta.price} />}
        {meta.price && <meta property="og:price:currency" content="CAD" />}

        <meta name="twitter:url" content={metaUrl} />
        <meta name="twitter:title" content={metaTitle} />
        <meta name="twitter:description" content={metaDescription} />
        <meta name="twitter:site" content="@liv_rent" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:image" content={metaImage} />
        <meta name="twitter:image:alt" content={imageAlt} />

        {paginated && getPaginationTags()}
        {showCanonical && <link rel="canonical" href={metaUrl} />}
        {showHrefLang && (
          <>
            <link
              rel="alternate"
              hrefLang="en"
              href={`https://liv.rent${path}`}
            />
            <link
              rel="alternate"
              hrefLang="fr"
              href={`https://liv.rent/fr${path}`}
            />
            <link
              rel="alternate"
              hrefLang="zh-CN"
              href={`https://liv.rent/zh${path}`}
            />
            <link
              rel="alternate"
              hrefLang="x-default"
              href={`https://liv.rent${path}`}
            />
          </>
        )}

        {meta.keywords && <meta name="keywords" content={meta.keywords} />}

        {hideCustomerSupport && (
          <style type="text/css">
            {`
            #wechat-overlay, .crisp-client { display: none !important }
          `}
          </style>
        )}
        {children}
      </NextHead>
      <script
        defer
        async
        type="application/ld+json"
        dangerouslySetInnerHTML={{
          __html: organizationSchema
        }}
      />
      {meta.schema && (
        <script
          defer
          async
          type="application/ld+json"
          dangerouslySetInnerHTML={{
            __html: meta.schema
          }}
        />
      )}
    </>
  )
}

export default Head
